import request from '@/utils/request'


// 查询举报类型列表
export function listReportType(query) {
  return request({
    url: '/user/report-type/list',
    method: 'get',
    params: query
  })
}

// 查询举报类型分页
export function pageReportType(query) {
  return request({
    url: '/user/report-type/page',
    method: 'get',
    params: query
  })
}

// 查询举报类型详细
export function getReportType(data) {
  return request({
    url: '/user/report-type/detail',
    method: 'get',
    params: data
  })
}

// 新增举报类型
export function addReportType(data) {
  return request({
    url: '/user/report-type/add',
    method: 'post',
    data: data
  })
}

// 修改举报类型
export function updateReportType(data) {
  return request({
    url: '/user/report-type/edit',
    method: 'post',
    data: data
  })
}

// 删除举报类型
export function delReportType(data) {
  return request({
    url: '/user/report-type/delete',
    method: 'post',
    data: data
  })
}
