import request from '@/utils/request'


// 查询轮播图列表
export function listSwiper(query) {
  return request({
    url: '/user/swiper/list',
    method: 'get',
    params: query
  })
}

// 查询轮播图分页
export function pageSwiper(query) {
  return request({
    url: '/user/swiper/page',
    method: 'get',
    params: query
  })
}

// 查询轮播图详细
export function getSwiper(data) {
  return request({
    url: '/user/swiper/detail',
    method: 'get',
    params: data
  })
}

// 新增轮播图
export function addSwiper(data) {
  return request({
    url: '/user/swiper/add',
    method: 'post',
    data: data
  })
}

// 修改轮播图
export function updateSwiper(data) {
  return request({
    url: '/user/swiper/edit',
    method: 'post',
    data: data
  })
}

// 删除轮播图
export function delSwiper(data) {
  return request({
    url: '/user/swiper/delete',
    method: 'post',
    data: data
  })
}
